.App {
  text-align: center;
}

.App-logo {
  width: 100vw;
  height: auto;
  pointer-events: none;
}


.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.imageDiv{
  float: left;
  margin-right: 1vh;  
  cursor: pointer;
  width: 7vh;
  height: 5.5vh;
}
.flag{
  width: 7vh;
  height: 5.5vh;
}
.imageContainer{
  position: absolute;
  top: 2%;
  left: 1%;
  width: 16vh;
  height: 14vh;
}